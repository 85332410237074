import { graphql, navigate } from "gatsby";
import React, { useEffect } from "react";
import Pagination from "../components/Pagination";
import Layout from "../layouts";
import SEO from "../components/seo";
import ContentBlockList from "../modules/content-block-list";
import Header from "../modules/header";
import PropTypes from "prop-types";
import { format } from "date-fns";

export const query = graphql`
  query CoursesIndexQuery($limit: Int, $skip: Int) {
    courses: allSanityCourse(skip: $skip, limit: $limit) {
      totalCount
      edges {
        node {
          id
          path
          slug {
            current
          }
          title
          excerpt
          image {
            asset {
              _id
              altText
              metadata {
                lqip
                dimensions {
                  width
                  height
                  aspectRatio
                }
              }
            }
            hotspot {
              height
              width
              x
              y
            }
          }
        }
      }
    }
  }
`;

const CoursesPage = props => {
  //TODO: Remove this when Courses page is complete
  useEffect(() => {
    navigate("/courses/farm-safety");
  }, []);

  const { page, numPages } = props.pageContext;
  const data = props.data;

  const refine = nextPage => {
    window.location.href = "/videos/" + nextPage;
  };

  const pageItems = data.courses.edges.map(item => ({
    title: item.node.title,
    text: item.node.description,
    image: item.node.image,
    slug: `/courses/${item.node.slug.current}`,
  }));

  return (
    <Layout>
      <SEO
        title="GrainGrowers Videos"
        excerpt="Watch videos on key topics in the GrainGrowers world"
      />
      <Header
        title="GrainGrowers Courses"
        text="Learn key topics in the GrainGrowers world"
        slug="videos"
        backgroundColour="orange"
        breadcrumbs={[{ title: "Courses", slug: "courses" }]}
      />
      <div className="container">
        {/* <ContentBlockList content={pageItems} usePlaceholderImages={true} /> */}
        {/* {JSON.stringify(pageItems, 2, null)} */}
        Coming soon
        {numPages > 1 && (
          <Pagination
            currentRefinement={page}
            nbPages={numPages}
            refine={refine}
          />
        )}
      </div>
    </Layout>
  );
};

CoursesPage.propTypes = {
  data: PropTypes.object,
  pageContext: PropTypes.object,
};

export default CoursesPage;
